<template>
  <div class="d-flex justify-content-between px-10 w-100 fs16">
    <div class="text-center">
      <p>
        <b>Tel-Aviv</b>(TLV)
      </p>
      <p>
        <b>14/04/21</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx-2">
        12:45
      </div>
    </div>
    <div class="df-c flex-column pt-7">
      <slot name="additionalData">
      </slot>
    </div>
    <div class="text-center">
      <p>
        <b>Berlin</b>(BRL)
      </p>
      <p>
        <b>18/04/21</b>
      </p>
      <div class="d-flex justify-content-center fs12">
        <img src="/assets/img/icons/plane-departure-light-black.svg" alt="" class="mx-2">
        18:45
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileFlightDesData",
  props: {
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>

</style>