import { render, staticRenderFns } from "./MobileFlightDesData.vue?vue&type=template&id=9523bd58&scoped=true&"
import script from "./MobileFlightDesData.vue?vue&type=script&lang=js&"
export * from "./MobileFlightDesData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9523bd58",
  null
  
)

export default component.exports